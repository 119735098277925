import { camelToSnakeCase } from "~/utils/string_utils";

export function getGiftPrefixedIntercomProps(map: Record<string, any>) {
  const prefixedMap = {
    "Referral URL": "/gift",
    referral_url: "/gift",
  } as Record<string, any>;
  const serialNumber = map["serialNo"];
  if (serialNumber) {
    prefixedMap["name"] = `Quick commerce - ${serialNumber}`;
  }
  for (const [key, value] of Object.entries(map)) {
    prefixedMap[camelToSnakeCase(`gift_${key}`)] = value;
  }
  return prefixedMap;
}
