import { Faqs } from "~/components/brand_l2/faqs";
import { FAQ } from "~/data/static_faqs/types";
import { CloseModalButton, ModalInnerFrame } from "~/components/modal";

export function GiftingFAQs(props: { faqs: FAQ[] }) {
  return (
    <ModalInnerFrame paddingClass="lg:p-6 p-4">
      <div class="flex w-full flex-col gap-5 lg:gap-6 ">
        <div class="flex w-full flex-row items-start justify-between  ">
          <div class="text-h3 text-baseTertiaryLight">FAQs</div>
          <CloseModalButton />
        </div>
        <Faqs faqs={props.faqs} showSerialNumber={false} />
      </div>
    </ModalInnerFrame>
  );
}
